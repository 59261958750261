<template>
    <v-theme-provider
        :theme="theme"
    >
        <v-app-bar
            color="navbg"
            class="pa-4"
            extended
            :extension-height="0"
        >
            <v-container>
                <v-row>
                    <v-col cols="5">
                        <v-img
                            class="mt-0"
                            :src="logo"
                            max-width="180"
                            max-height="150"
                        />
                    </v-col>
                    <v-spacer />
                    <v-col>
                        <div class="d-flex justify-center">
                            <ProgressionCounter
                                :time="time"
                                :timeleft="timeleft"
                            />
                        </div>
                    </v-col>
                    <v-spacer />

                    <v-col
                        cols="5"
                        class="text-right pr-12"
                    >
                        <v-row justify="end">
                            <v-col
                                class="pa-0 pt-5"
                                align-self="center"
                            >
                                <div class="text-navtext text-h4 font-weight-regular">
                                    <slot name="title">
                                        accadis <span class="font-weight-bold">Infoanzeige</span>
                                    </slot>
                                </div>
                            </v-col>
                            <v-col
                                cols="2"
                                class="pa-0 pl-5"
                            >
                                <v-img
                                    :src="image"
                                    class="mr-2 mt-3"
                                    width="61"
                                    height="61"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-app-bar>
    </v-theme-provider>
</template>

<script lang="ts" setup>
import { useTheme } from 'vuetify';

const props = defineProps({
    theme: { type: String, required: false, default: () => 'accadisHSTheme' },
    image: { type: String, required: true },
    time: { type: Number, required: false, default: () => 10 },
    timeleft: { type: Number, required: false, default: () => 10 },
});

const theme = computed(() => props.theme);
const image = computed(() => props.image);
const time = computed(() => props.time);
const timeleft = computed(() => props.timeleft);

const currentTheme = useTheme();
const logo = computed(() => currentTheme.current.value?.variables['logo'].toString());
</script>
